
import {
  Color,
  ShaderMaterial,
} from "three"
import { Text } from "troika-three-text"
import GSAP from "gsap"
import mirtha from "fonts/MirthaDisplayBold.woff"

import canvas from "lib/canvas"
import raf from "lib/raf"
import scroll from "lib/scroll"
import resize from "lib/eventsResize"

import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'
import { ITextSlice } from 'data/'

interface TextSlice extends ITextSlice {}

class TextSlice {
  constructor({ container, text }) {

    this.domElements = {
      container: container,
      text: text
    }

    this.glElements = {
      geometry: new Text(),
      material: new ShaderMaterial()
    }

    this.bind()

    this.createBounds()
    this.createGeometry()
    this.createMaterial()
    this.addToScene()
    this.onResize()

    raf.subscribe('text slice',this.update)
    resize.subscribe('text slice', this.onResize)
  }

  bind() {
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  // ------------------------------------------------ SETUP
  createBounds() {
    const bounds = this.domElements.container.getBoundingClientRect()

    this.bounds = {
      top: bounds.top + scroll.ASScroll.currentPos,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      canvasWidth: canvas.container.offsetWidth,
      canvasHeight: canvas.container.offsetHeight
    }

  }

  createGeometry() {
    this.glElements.geometry.text = `${this.domElements.text.innerText}`
    this.glElements.geometry.font = mirtha

    this.glElements.geometry.textAlign = getComputedStyle(this.domElements.text).textAlign
    
    this.glElements.geometry.anchorX = 'center'
    this.glElements.geometry.anchorY = 'middle'

    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElements.text).fontSize)
    this.glElements.geometry.lineHeight = 1.15
    this.glElements.geometry.sdfGlyphSize = 64
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_viewportSizes: { value: [window.innerWidth, window.innerHeight] },
      u_color: { value : new Color(getComputedStyle(this.domElements.text).color)},
      u_alpha: { value : 1.0 },
      u_strength: { value: 0.0 },
      u_textLength: { value: 0.0}
    }
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
    this.glElements.geometry.material = this.glElements.material
    this.glElements.geometry.layers.set(1)
  }

  addToScene() {
    canvas.scene.add(this.glElements.geometry)
    this.glElements.geometry.sync()
    this.glElements.geometry.addEventListener('synccomplete', () => {
      this.glElements.geometry.sdfGlyphSize = 512
      this.onResize()
    })
  }

  setColor(theme : string) {
    const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    const timeline = GSAP.timeline()

    timeline.to(initialColor, {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      onUpdate: () => {
        this.glElements.geometry.material.uniforms.u_color.value.r = initialColor.r
        this.glElements.geometry.material.uniforms.u_color.value.g = initialColor.g
        this.glElements.geometry.material.uniforms.u_color.value.b = initialColor.b
      }
    })
    return timeline
  }
  // ------------------------------------------------ EVENTS
  onResize() {
    this.createBounds()
    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElements.text).fontSize)
  }

  // ------------------------------------------------ POSITIONS
  setPosition() {
    this.glElements.geometry.position.x = this.bounds.left - this.bounds.canvasWidth / 2 + this.bounds.width / 2
    this.glElements.geometry.position.y = scroll.ASScroll.currentPos -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2
  }

  // ------------------------------------------------ RAF
  update() {
    this.setPosition()
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    raf.unsubscribe('text slice')
    resize.unsubscribe('text slice')
    canvas.scene.remove( this.glElements.geometry )
  }
}

export default TextSlice