
import {
  ShaderMaterial,
  Color,
} from "three"
import GSAP from 'gsap'
import { Text } from "troika-three-text"

import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'

import { 
  IcontactTextGl
} from 'data/'

import mirtha from "fonts/MirthaDisplayBold.woff"

interface contactText extends IcontactTextGl {}

class contactText  {
  constructor({ container, text, scene })  {
    
    this.text = text
    this.container = container

    this.glElements = {
      geometry: new Text(),
      material: new ShaderMaterial(),
      scene: scene
    }

    this.init()
  }


  init() {
    this.bind()
    this.createGeometry()
    this.createMaterial()
    this.addToScene()
  }

  bind() {
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  // ------------------------------------------------ SETUP

  createGeometry() {
    this.glElements.geometry.text = `${this.text}`
    this.glElements.geometry.font = mirtha

    this.glElements.geometry.textAlign = getComputedStyle(this.container).textAlign
    
    this.glElements.geometry.anchorX = 'center'
    this.glElements.geometry.anchorY = 'middle'

    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.container).fontSize)
    this.glElements.geometry.lineHeight = this.container.getAttribute('line-height')
    this.glElements.geometry.sdfGlyphSize = 64
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_viewportSizes: { value: [window.innerWidth, window.innerHeight] },
      u_color: { value : new Color(getComputedStyle(this.container).color)},
      u_alpha: { value : 0.5 },
      u_strength: { value: 0.0 },
      u_textLength: { value: 0.0}
    }
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
    this.glElements.geometry.material = this.glElements.material

  }

  addToScene() {
    this.glElements.geometry.sync()
    this.glElements.scene.add(this.glElements.geometry)
    this.glElements.geometry.addEventListener('synccomplete', () => {
      this.glElements.geometry.sdfGlyphSize = 512
      this.onResize()
    })
  }
  setColor(theme : string) {
    const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    const timeline = GSAP.timeline()

    timeline.to(initialColor, {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      onUpdate: () => {
        this.glElements.geometry.material.uniforms.u_color.value.r = initialColor.r
        this.glElements.geometry.material.uniforms.u_color.value.g = initialColor.g
        this.glElements.geometry.material.uniforms.u_color.value.b = initialColor.b
      }
    })
    return timeline
  }
  
  // ------------------------------------------------ EVENTS
  onResize() {
    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.container).fontSize)
  }

  // ------------------------------------------------ RAF
  update() {
    this.glElements.geometry.position.x = 0
    this.glElements.geometry.position.y = 0
    this.glElements.geometry.position.z = 0
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    this.glElements.scene.remove( this.glElements.geometry )
  }
}

export default contactText