import React, { useEffect, useContext} from "react"
import { graphql, PageProps } from "gatsby"
import GSAP from "gsap"

import * as scss from "./Page.module.scss"
import { SEO } from 'components/seo/Seo'
import SliceZone from "components/sliceZone/SliceZone"
import { ISinglePageTemplateData } from "data/dataStruct"

import Scroll from 'lib/scroll'

const Index: React.FC<PageProps<ISinglePageTemplateData>> = ({ data }) => {
  const { page } = data
  const componentName = page.uid
  
  useEffect(() => {
    if (Scroll.isSet) {

      const timeLine = GSAP.timeline({})

      const test = new Promise((resolve, reject) => {
        Scroll.disable()
        setTimeout(() => {
          resolve(true);
        }, 1000);
      })

      test.then(() => {
        Scroll.reset()
      })
      // timeLine.add(() => {
      //   console.log('LEAVE')
      //   Scroll.disable()
      // })
      // timeLine.add(() => {
      //   console.log('ENTER')
      //   Scroll.reset()
      // }, "+=0.5")
      // Scroll.reset()
    }
  }, [])

  return (
    <div className={scss.root}>
      <SEO title={page.data.meta_title} description={page.data.meta_description} />
      { page.data.body && (<SliceZone data={page.data.body} uid={page.uid}/>) }
    </div>
  )
}

export default Index

// ------------------------------ QUERY
export const query = graphql`
query page($uid: String!) {
  page : prismicPageTemplate(uid: {eq: $uid}) {
    ...pageTemplateData
  }
}
`