
import {
  Vector2,
} from "three"

import NormalizeWheel from "normalize-wheel"
import ClientListItem from "./clientListItem/ClientListItem"

import raf from "lib/raf"
import scroll from "lib/scroll"
import resize from "lib/eventsResize"
import mouseMove from "lib/eventMouseMove"

import { lerp } from "lib/math"

import { IClientList } from "data/"

interface ClientList extends IClientList {}

class ClientList {
  constructor( { container, texts } ) {

    this.domElements = {
      container: container,
      texts: texts
    }
    
    this.initGlElements()
    this.initBounds()
    this.initOptions()
    this.initScroll()
    this.bind()

    this.createBounds()
    this.createTexts()

    this.addEvents()
    this.onResize()

    raf.subscribe('client list',this.update)
    resize.subscribe('client list', this.onResize)
    mouseMove.subscribe('client list', this.onTouchMove)
  }

  // ------------------------------------------------ INIT
  initGlElements() {
    this.glElements = {
      listItem: [],
      mouse: new Vector2(),
    }
  }

  initBounds() {
    this.bounds = {
      container: null
    }
  }

  initOptions() {
    this.options = {
      isDown: false
    }
  }

  initScroll() {
    this.scroll = {
      ease: 0.05,
      speed: 2,
      direction: '',
      up : 0,
      current: 0,
      target: 0,
      last: 0,
      position: 0,
      start: 0
    }
  }

  bind() {
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
  }

  // ------------------------------------------------ CREATE OBJECTS

  createBounds() {
    const bounds = this.domElements.container.getBoundingClientRect()

    this.bounds.container = {
      top: bounds.top + scroll.ASScroll.currentPos,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
    }

  }

  createTexts() {
    this.glElements.listItem = this.domElements.texts.map((item) => {
      let itemWebgl = new ClientListItem({
        domElement: item,
        totalWidth: this.getClientListWidth()
      })
      return itemWebgl
    })
  }

  // ------------------------------------------------ EVENTS
  addEvents() {
    window.addEventListener('mousewheel', this.onWheel.bind(this))
    window.addEventListener('wheel', this.onWheel.bind(this))
    window.addEventListener('mousedown', this.onTouchDown.bind(this))
    window.addEventListener('mouseleave', this.onTouchUp.bind(this))
    window.addEventListener('mouseup', this.onTouchUp.bind(this))
    window.addEventListener('touchstart', this.onTouchDown.bind(this))
    window.addEventListener('touchmove', this.onTouchMove.bind(this))
    window.addEventListener('touchend', this.onTouchUp.bind(this))
  }

  // ------------------------------------------------ METHODS
  setTheme(theme: string) {
    this.glElements.listItem.forEach( item => item.setColor(theme) )
  }

  getClientListWidth() {
    let clientListWidth = 0
    this.domElements.texts.forEach((item) => {
      clientListWidth += item.getBoundingClientRect().width + 20
    })

    return clientListWidth
  }

  onResize() {
    this.createBounds()
    this.glElements.listItem.forEach(media => media.onResize(this.getClientListWidth()))
  }

  onWheel (event: WheelEvent) {
    const normalized = NormalizeWheel(event)
    const speed = normalized.pixelY

    this.scroll.target += speed * 0.5
  }

  onTouchDown (event: TouchEvent & MouseEvent) {

    this.options.isDown = true
  
    this.scroll.position = this.scroll.current
    this.scroll.start = event.touches ? event.touches[0].clientX : event.clientX
  }
  
  onTouchMove (event: TouchEvent & MouseEvent) {

    this.glElements.mouse.x = event.clientX / window.innerWidth * 2 - 1
    this.glElements.mouse.y = - (event.clientX / window.innerHeight) * 2 + 1

    if (!this.options.isDown) return

    const y = event.touches ? event.touches[0].clientX : event.clientX
    const distance = (this.scroll.start - y) * 2
  
    this.scroll.target = this.scroll.position + distance
  }
  
  onTouchUp (event: TouchEvent & MouseEvent) {
    const y = event.changedTouches ? event.changedTouches[0].clientX : event.clientX
    this.scroll.up = y
    this.options.isDown = false
  }

  // ------------------------------------------------ RAF

  update() {

    this.scroll.target += this.scroll.speed

    this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.ease)
    if (this.scroll.current > this.scroll.last) {
      this.scroll.direction = 'down'
      this.scroll.speed = 2
    } else if (this.scroll.current < this.scroll.last) {
      this.scroll.direction = 'up'
      this.scroll.speed = -2
    }

    this.glElements.listItem.forEach(text => text.update(this.scroll, this.scroll.direction, scroll.ASScroll.currentPos))

    this.scroll.last = this.scroll.current
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    raf.unsubscribe('client list')
    resize.unsubscribe('client list')
    mouseMove.unsubscribe('client list')

    window.removeEventListener('mousewheel', this.onWheel.bind(this))
    window.removeEventListener('wheel', this.onWheel.bind(this))
    window.removeEventListener('mousedown', this.onTouchDown.bind(this))
    window.removeEventListener('mouseup', this.onTouchUp.bind(this))
    window.removeEventListener('touchstart', this.onTouchDown.bind(this))
    window.removeEventListener('touchmove', this.onTouchMove.bind(this))
    window.removeEventListener('touchend', this.onTouchUp.bind(this))

    this.glElements.listItem.forEach(text => text.destroy())
  }
}

export default ClientList