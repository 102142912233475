
import {
  ShaderMaterial,
  Color,
} from "three"
import GSAP from 'gsap'
import { Text } from "troika-three-text"
import scroll from "lib/scroll"

import canvas from "lib/canvas"
import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'
import { TScroll, IClientListItem } from 'data/'
import mirtha from "fonts/MirthaDisplayRegular.woff"

interface ClientListItem extends IClientListItem {}

class ClientListItem  {
  constructor( { domElement, totalWidth } )  {
    
    this.domElement = domElement

    this.bounds = {
      extra: 0,
      totalWidth: totalWidth,
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      canvasWidth: 0,
      canvasHeight: 0
    }

    this.glElements = {
      geometry: new Text(),
      material: new ShaderMaterial(),
    }

    this.options = {
      isBefore: null,
      isAfter: null
    }

    this.bind()
    
    setTimeout(() => {
      this.createBounds()
      }, 1000)
      
    this.createGeometry()
    this.createMaterial()
    this.addToScene()
  }


  bind() {
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  // ------------------------------------------------ SETUP

  createBounds() {
    const bounds = this.domElement.getBoundingClientRect()

    this.bounds = {
      ...this.bounds,
      top: bounds.top + scroll.ASScroll.currentPos,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      canvasWidth: canvas.container.offsetWidth,
      canvasHeight: canvas.container.offsetHeight
    }

  }

  createGeometry() {
    this.glElements.geometry.text = `${this.domElement.innerText}`
    this.glElements.geometry.font = mirtha

    this.glElements.geometry.textAlign = getComputedStyle(this.domElement).textAlign
    
    this.glElements.geometry.anchorX = 'center'
    this.glElements.geometry.anchorY = 'middle'

    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
    this.glElements.geometry.lineHeight = 1.15

    this.glElements.geometry.sdfGlyphSize = 32
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_viewportSizes: { value: [window.innerWidth, window.innerHeight] },
      u_color: { value : new Color(getComputedStyle(this.domElement).color)},
      u_alpha: { value : 1.0 },
      u_strength: { value: 0.0 },
      u_textLength: { value: 0.0}
    }
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
    this.glElements.geometry.material = this.glElements.material
    this.glElements.geometry.layers.set(1)
    
  }

  addToScene() {
    canvas.scene.add(this.glElements.geometry)
    this.glElements.geometry.sync()
    this.glElements.geometry.addEventListener('synccomplete', () => {
      this.glElements.geometry.sdfGlyphSize = 512
      this.createBounds()
    })
  }
  setColor(theme : string) {
    const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    const timeline = GSAP.timeline()

    timeline.to(initialColor, {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      onUpdate: () => {
        this.glElements.geometry.material.uniforms.u_color.value.r = initialColor.r
        this.glElements.geometry.material.uniforms.u_color.value.g = initialColor.g
        this.glElements.geometry.material.uniforms.u_color.value.b = initialColor.b
      }
    })
    return timeline
  }

  
  // ------------------------------------------------ EVENTS
  onResize(totalWidth : number) {
    this.bounds.totalWidth = totalWidth
    this.bounds.extra = 0


    this.createBounds()
    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
  }

  // ------------------------------------------------ POSITIONS
  setPosition(y = 0) {
    this.glElements.geometry.position.x = (this.bounds.left - this.bounds.canvasWidth / 2) - y + this.bounds.width / 2 + this.bounds.extra
    this.glElements.geometry.position.y = scroll.ASScroll.currentPos -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2
  }

  // ------------------------------------------------ RAF
  update(y: TScroll, direction: string) {

    this.setPosition(y.current)

    const limitTop = (this.bounds.canvasWidth / 2 + this.bounds.width)
    const limitBottom = -(this.bounds.canvasWidth / 2 + this.bounds.width)

    this.options.isBefore = this.glElements.geometry.position.x > limitTop
    this.options.isAfter = this.glElements.geometry.position.x < limitBottom

    if (direction === 'up' && this.options.isBefore) {
      this.bounds.extra -= this.bounds.totalWidth
      this.options.isBefore = false
      this.options.isAfter = false
    }

    if (direction === 'down' && this.options.isAfter) {
      this.bounds.extra += this.bounds.totalWidth
      this.options.isBefore = false
      this.options.isAfter = false
    }

    this.glElements.material.uniforms.u_strength.value = ((y.current - y.last) / 2) * 10
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    canvas.scene.remove( this.glElements.geometry )
  }
}

export default ClientListItem