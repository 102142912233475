import React, { useEffect, useContext, useRef } from "react"
import *  as scss from './Contact.module.scss'
import GSAP from 'gsap'
import {
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"

import ContactGL from "componentsWebgl/contact/ContactGL"

const componentName = 'Contact'

const Contact = ({data, uid}) => {

  // ------------------------------------------------ CONTEXT
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ DATA
  const slice = globalState.pages_template_slices[uid]?.slices[data.id]

  // ------------------------------------------------ REFS
  const refContainer = useRef<HTMLDivElement>()
  const refText = useRef()

  // ------------------------------------------------ FIND & HANDLE COMPONENTS WEBGL
  const handleWebgl =  () => {
    if (slice) {
      const object = new ContactGL({
        container: refContainer.current,
        text: `${data.primary.title1} ☺`,
      })
      slice.webgl = object
    }
  }

  const handleMouseEnter = () => {
    if (slice) {
      slice.webgl.onMouseEnter()
    }
  }

  const handleMouseLeave = () => {
    if (slice) {
      slice.webgl.onMouseLeave()
    }
  }

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    globalState.webgl && handleWebgl()
    return () => {
      globalState.webgl && slice.webgl.destroy()
    }
  }, [globalState.webgl])

  useEffect(() => {
    if (slice && slice.webgl) {
      slice.webgl.setTheme(globalState.theme)
    }
    const timeline = GSAP.timeline()
    timeline.to(refText.current, {
      color: globalState.theme === "outerSpace" ? '#f5cdd5' : '#283f38'
    })
  }, [globalState.theme])

  return (
    <div className={scss.root} ref={refContainer} >
      <a href={data.primary.link.url} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={refText}>☺</a>
    </div>
  )
}

export default Contact