import React, { useEffect, useContext, useRef } from "react"
import *  as scss from './ClientsList.module.scss'

import {
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"

import ClientList from "componentsWebgl/clientList/ClientList"

const componentName = 'ClientsList'

const ClientsList = ({data, uid}) => {

  // ------------------------------------------------ CONTEXT
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ DATA
  const slice = globalState.pages_template_slices[uid]?.slices[data.id]
  const clients = [...data.items, ...data.items]

  // ------------------------------------------------ REFS
  const refContainer = useRef<HTMLDivElement>()
  const refTexts = React.useRef([])
  refTexts.current = []

  const addToRefsTexts = (item: HTMLHeadElement) => {
    if (item && !refTexts.current.includes(item)) {
      refTexts.current.push(item)
    }
  }

  // ------------------------------------------------ FIND & HANDLE COMPONENTS WEBGL
  const handleWebgl =  () => {
    if (slice)   {
      const object = new ClientList({
        container: refContainer.current,
        texts: refTexts.current,
      })
      slice.webgl = object
    }
  }

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    globalState.webgl && handleWebgl()
    return () => {
      globalState.webgl && slice.webgl.destroy()
    }
  }, [globalState.webgl])

  // ------------------------------------------------ USEEFFECT THEME
  useEffect(() => {
    if (slice && slice.webgl) {
      slice.webgl.setTheme(globalState.theme)
    }
  }, [globalState.theme])

  return (
    <div className={scss.root} ref={refContainer}>
      <div className={scss.container}>
        {
          clients.map(({client}, index : Number) => (
            <div 
              className={scss.text}
              key={`infiniteGallery__image--${index}`}
              draggable={false}>
              <span ref={addToRefsTexts}>
                { index === 6 || index === 13 ? `${client}` : `${client},`}
              </span>
            </div>
            )
          )
        }
      </div>
    </div>
  )
}

export default ClientsList