
import {
  Mesh,
  Scene,
  WebGLMultisampleRenderTarget,
  ShaderMaterial,
  Clock,
  Raycaster,
  Vector2,
  TorusGeometry,
  DoubleSide,
  Color
} from "three"
import GSAP from 'gsap'
import raf from "lib/raf"
import scroll from "lib/scroll"
import resize from "lib/eventsResize"
import canvas from "lib/canvas"

import { 
  IContactGl
} from 'data/'

import ContactText from "./contactText/ContactText"

import vertex from './_vertex.glsl'
import fragment from './_fragment.glsl'

interface Contact extends IContactGl {}

class Contact {
  constructor( { container, text } ) {

    this.domElements = {
      container: container,
      text: text
    }

    this.initGlElements()
    this.bind()

    this.createBounds()
    setTimeout(() => {
      this.createBounds()
      }, 1000)
    this.createText()
    this.createMaterial()
    this.createMesh()
    this.addToScene()

    this.onResize()

    raf.subscribe('contact',this.update)
    resize.subscribe('contact', this.onResize)

  }

  // ------------------------------------------------ INIT
  initGlElements() {

    let shape = null

    if (window.innerWidth > 1920) {
      shape = new TorusGeometry(400,155,250,250)
    }

    if (window.innerWidth <= 1920) {
      shape = new TorusGeometry(250,85,250,250)
    }

    if (window.innerWidth <= 1024) {
      shape = new TorusGeometry(200,75,250,250)
    }

    if (window.innerWidth <= 768) {
      shape = new TorusGeometry(150,65,250,250)
    }

    if (window.innerWidth <= 600) {
      shape = new TorusGeometry(125,85,250,250)
    }

    this.glElements = {
      contactText: null,
      plane: null,
      geometry: shape,
      clock: new Clock(),
      material: new ShaderMaterial(),
      renderTarget : {
        render : new WebGLMultisampleRenderTarget (
          canvas.container.offsetWidth,
          canvas.container.offsetHeight
        ),
        camera: canvas.camera.clone(),
        scene : new Scene()
      }
    }
    // this.glElements.renderTarget.scene.background = new Color('#283f38')

  }

  bind() {
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  // ------------------------------------------------ CREATE OBJECTS
  createBounds() {
    const bounds = this.domElements.container.getBoundingClientRect()

    this.bounds = {
      top: bounds.top + scroll.ASScroll.currentPos,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
    }
  }

  createText() {
    this.glElements.contactText = new ContactText({
      container: this.domElements.container,
      text: this.domElements.text,
      scene: this.glElements.renderTarget.scene,
    }) 
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_time: { value: 0 },
      u_globalTime: { value: 0.55},
      u_texture: { value: this.glElements.renderTarget.render.texture },
      u_repeat: { value: { y: 2.0, x: 2.0 } },
      u_animateHorizontal: { value: 1.0 },
      u_axis: { value: {x: 1.0, y: 0.0, z: 0.0} },
      u_direction: { value: {x: 1.0, y: 0.0, z: 0.0} },
      u_strength: { value: 0.0 }
    }
    this.glElements.material.transparent = false
    this.glElements.material.side = DoubleSide
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
  }

  createMesh() {
    this.glElements.plane = new Mesh( this.glElements.geometry, this.glElements.material )
    this.glElements.plane.rotation.x = -Math.PI
    this.glElements.plane.layers.set(1)

  }

  addToScene() {
    canvas.scene.add( this.glElements.plane )
  }
  
  // ------------------------------------------------ METHODS
  onMouseEnter() {
    const timeline = GSAP.timeline()
    timeline.to(this.glElements.plane.rotation, {
      x: 0,
    })
  }

  onMouseLeave() {
    const timeline = GSAP.timeline()
    timeline.to(this.glElements.plane.rotation, {
      x: -Math.PI,
    })
  }

  setTheme(theme: string) {
    // const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    // const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    // const timeline = GSAP.timeline()

    // timeline.to(initialColor, {
    //   r: newColor.r,
    //   g: newColor.g,
    //   b: newColor.b,
    //   onUpdate: () => {
    //     this.glElements.renderTarget.scene.background.r = initialColor.r
    //     this.glElements.renderTarget.scene.background.g = initialColor.g
    //     this.glElements.renderTarget.scene.background.b = initialColor.b
    //   }
    // })

    this.glElements.contactText.setColor(theme)
  }

  onResize() {
    this.createBounds()
    this.glElements.renderTarget.render.setSize(canvas.container.offsetWidth,canvas.container.offsetHeight)
    this.glElements.renderTarget.camera = canvas.camera.clone()  
    this.glElements.contactText.onResize()
  }

  // ------------------------------------------------ POSITIONS
  setPosition() {
    this.glElements.plane.position.x = this.bounds.left - canvas.width / 2 + this.bounds.width / 2
    this.glElements.plane.position.y = scroll.ASScroll.currentPos -this.bounds.top + canvas.height / 2 - this.bounds.height / 2
  }

  // ------------------------------------------------ RAF
  updateRT() {
    canvas.renderer.setRenderTarget(this.glElements.renderTarget.render)
    canvas.renderer.render(this.glElements.renderTarget.scene, this.glElements.renderTarget.camera)
    canvas.renderer.setRenderTarget(null)
  }

  update() {
    this.updateRT()
    this.setPosition()

    this.glElements.plane.rotation.z += 0.01

    this.glElements.material.uniforms.u_time.value = this.glElements.clock.getElapsedTime()
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    raf.unsubscribe('contact')
    resize.unsubscribe('contact')

    this.glElements.contactText.destroy()
    canvas.scene.remove( this.glElements.plane )
  }
}

export default Contact