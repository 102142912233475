import React, {Fragment} from 'react'
import Text from 'components/text/Text'
import ClientsList from 'components/clientsList/ClientsList'
import Contact from "components/contact/Contact"

interface IProps {
  uid: string
  data: any
}

const SliceZone = ( {data, uid}: IProps ) => {
  const sliceComponents = {
    text: Text,
    clients_list: ClientsList,
    contact: Contact,
  }

  const sliceZoneContent = data.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent data={slice} uid={uid} key={`slice-${index}`} />
    }
    return null
  })


  return <Fragment>{sliceZoneContent}</Fragment>
}

export default SliceZone