import React, { useEffect, useContext, useRef } from "react"
import *  as scss from './Text.module.scss'

import {
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"

import TextSlice from "componentsWebgl/textSlice/TextSlice"

const componentName = 'Text'

const Text = ({data, uid}) => {

  
  // ------------------------------------------------ CONTEXT
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ DATA
  const slice = globalState.pages_template_slices[uid]?.slices[data.id]

  // ------------------------------------------------ REFS
  const refContainer = useRef<HTMLDivElement>()
  const refTexts = useRef()

  // ------------------------------------------------ FIND & HANDLE COMPONENTS WEBGL
  const handleWebgl =  () => {
    if (slice) {
      const object = new TextSlice({
        container: refContainer.current,
        text: refTexts.current,
      })
      slice.webgl = object
    }
  }

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    globalState.webgl && handleWebgl()
    return () => {
      globalState.webgl && slice.webgl.destroy()
    }
  }, [globalState.webgl])

  // ------------------------------------------------ USEEFFECT THEME
  useEffect(() => {
    if (slice && slice.webgl) {
      slice.webgl.setColor(globalState.theme)
    }
  }, [globalState.theme])

  return (
    <div className={scss.root} ref={refContainer}>
      <div ref={refTexts} dangerouslySetInnerHTML={{__html: data.primary.text.html}} />
    </div>
  )
}

export default Text